import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customerName", "customerPhone", "receiverName", "receiverPhone"]

  copyCustomerName(e) {
    e.preventDefault()

    this.receiverNameTarget.value = this.customerNameTarget.value
  }

  copyCustomerPhone(e) {
    e.preventDefault()

    this.receiverPhoneTarget.value = this.customerPhoneTarget.value
  }
}
